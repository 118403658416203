#footer{
  clear:both
}
@media(max-width: 1279px){
body #supportTrigger,body .online-chat-root .device-type-mobile .popup-notification{
    bottom:120px !important;
    right:15px !important;
    z-index:9999 !important
}
body .online-chat-root.online-chat-root-MeTalk .online-chat-components-wrapper #supportTrigger.trigger{
    bottom:120px !important;
    right:15px !important
}
body .online-chat-widget-draggable-wrapper{
    top:unset !important;
    bottom:120px !important
}
}