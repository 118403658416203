.footer-additional[data-v-5679cb73]{
  background-color:#373737;
  padding:30px 13px 255px;
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  font-size:14px;
  color:#fff;
  line-height:1
}
.footer-additional p[data-v-5679cb73]{
  color:#fff;
  margin-bottom:0
}
@media(min-width: 1280px){
.footer-additional[data-v-5679cb73]{
    padding:30px 100px 40px
}
}
.footer-additional__container[data-v-5679cb73]{
  background-image:url(/var/resources/static/images/attention-text-mobile6dc8.svg);
  background-repeat:no-repeat;
  background-position:bottom;
  background-size:contain;
  padding-bottom:85px
}
.footer-additional__container a[data-v-5679cb73]{
  color:#fff;
  text-decoration:none
}
@media(min-width: 640px){
.footer-additional__container[data-v-5679cb73]{
    background-image:url(/var/resources/static/images/attention-text-desktop872f.svg);
    padding-bottom:50px;
    background-size:contain
}
}
@media(min-width: 1280px){
.footer-additional__container[data-v-5679cb73]{
    padding-bottom:80px
}
}
@media(min-width: 1920px){
.footer-additional__container[data-v-5679cb73]{
    padding-bottom:110px
}
}
.footer-additional__copyright[data-v-5679cb73]{
  margin-bottom:22px
}
@media(min-width: 1280px){
.footer-additional__copyright[data-v-5679cb73]{
    display:flex;
    flex-direction:row;
    margin-bottom:0
}
}
.footer-additional__copyright p[data-v-5679cb73]{
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  font-size:14px;
  line-height:19px;
  margin:0
}
.footer-additional__copyright p[data-v-5679cb73]:first-child{
  margin-bottom:12px
}
@media(min-width: 1280px){
.footer-additional__copyright p[data-v-5679cb73]:first-child{
    flex:1
}
}
.footer-additional__phone[data-v-5679cb73]{
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  font-size:14px;
  text-transform:uppercase;
  margin-bottom:28px;
  display:flex;
  flex-direction:row;
  align-items:center;
  line-height:1
}
@media(min-width: 1280px){
.footer-additional__phone[data-v-5679cb73]{
    display:none
}
}
.footer-additional__content p[data-v-5679cb73]{
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  line-height:1;
  font-size:14px;
  margin:0 0 10px 0
}
.footer-additional__content p[data-v-5679cb73]:last-child{
  margin:0
}
.footer-additional__number[data-v-5679cb73],.footer-additional__number a[data-v-5679cb73]{
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  font-size:14px;
  font-weight:bold;
  font-size:20px;
  margin-right:10px;
  color:#fff;
  line-height:1;
  text-decoration:none
}
.footer-additional .itech-link[data-v-5679cb73]{
  display:inline-block;
  font-weight:normal;
  font-size:16px;
  letter-spacing:-0.04em;
  color:#fff;
  text-decoration:none;
  padding:0;
  width:100%
}
@media(min-width: 1280px){
.footer-additional .itech-link[data-v-5679cb73]{
    text-align:right
}
}
@media print{
.footer[data-v-5679cb73],.footer-additional[data-v-5679cb73]{
    display:none
}
}
@media(max-width: 1279px){
body #supportTrigger[data-v-5679cb73],body .online-chat-root .device-type-mobile .popup-notification[data-v-5679cb73]{
    bottom:120px !important;
    right:15px !important;
    z-index:9999 !important
}
body .online-chat-root.online-chat-root-MeTalk .online-chat-components-wrapper #supportTrigger.trigger[data-v-5679cb73]{
    bottom:120px !important;
    right:15px !important
}
body .online-chat-widget-draggable-wrapper[data-v-5679cb73]{
    top:unset !important;
    bottom:120px !important
}
}