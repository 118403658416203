.page-footer__scroll_button[data-v-62e3a5d6]{
  position:fixed;
  width:48px;
  height:48px;
  bottom:20px;
  left:35px;
  cursor:pointer;
  z-index:25;
  display:block;
  opacity:0;
  transition:opacity .4s linear
}
@media(max-width: 1280px){
.page-footer__scroll_button[data-v-62e3a5d6]{
    display:none
}
}
.page-footer__scroll_button_visible[data-v-62e3a5d6]{
  opacity:1
}