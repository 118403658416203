.cookies-wrapper[data-v-3e53c2e7]{
  position:fixed;
  display:flex;
  flex-direction:row;
  padding:21px 67px 30px 26px;
  z-index:10000;
  display:none;
  width:100%;
  max-width:1220px;
  background:#fff;
  filter:drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.14));
  bottom:26px;
  border-radius:5px;
  left:50%;
  transform:translateX(-50%)
}
@media(max-width: 1024px){
.cookies-wrapper[data-v-3e53c2e7]{
    border-radius:0;
    flex-direction:column;
    padding:25px 21px 10px 21px;
    bottom:0
}
}
.cookies-wrapper-close[data-v-3e53c2e7]{
  position:absolute;
  top:15px;
  right:28px;
  width:15px;
  height:15px;
  cursor:pointer
}
@media(max-width: 1024px){
.cookies-wrapper-close[data-v-3e53c2e7]{
    top:10px;
    right:21px
}
}
.cookies-wrapper-close[data-v-3e53c2e7]::after,.cookies-wrapper-close[data-v-3e53c2e7]::before{
  content:"";
  height:16px;
  width:2px;
  background:#373737;
  position:absolute;
  top:0;
  left:50%
}
.cookies-wrapper-close[data-v-3e53c2e7]::after{
  transform:rotate(45deg)
}
.cookies-wrapper-close[data-v-3e53c2e7]::before{
  transform:rotate(-45deg)
}
.cookies-text[data-v-3e53c2e7]{
  font-size:16px;
  line-height:120%;
  color:#373737;
  margin:0
}
@media(max-width: 1024px){
.cookies-text[data-v-3e53c2e7]{
    margin-bottom:15px
}
}
.cookies-button[data-v-3e53c2e7]{
  border-radius:5px;
  width:400px;
  height:44px;
  cursor:pointer;
  -webkit-user-select:none;
          user-select:none;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#00aafa;
  color:#fff;
  font-weight:600;
  font-size:16px;
  color:#fff
}
@media(max-width: 1024px){
.cookies-button[data-v-3e53c2e7]{
    width:100%
}
}