.footer[data-v-3e2f15be]{
  width:100%;
  background-color:#fff
}
@media(min-width: 1024px){
.footer[data-v-3e2f15be]{
    border-top:1px solid #dfe0e4
}
}
.footer__wrapper[data-v-3e2f15be]{
  padding:0;
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  justify-content:space-between;
  box-sizing:border-box;
  margin:0 auto
}
@media(min-width: 1024px){
.footer__wrapper[data-v-3e2f15be]{
    padding-top:60px
}
}
@media(min-width: 1280px){
.footer__wrapper[data-v-3e2f15be]{
    padding:70px 10px 35px;
    max-width:1440px
}
}
.footer__group[data-v-3e2f15be]{
  width:100%;
  display:flex;
  flex-direction:column;
  border-top:1px solid #dfe0e4;
  margin-bottom:20px;
  box-sizing:border-box
}
.footer__group[data-v-3e2f15be]:last-child{
  margin-bottom:0
}
@media(min-width: 1024px){
.footer__group[data-v-3e2f15be]{
    width:18%;
    justify-content:space-between;
    align-self:flex-start;
    border-top:none
}
}
.footer__item[data-v-3e2f15be]{
  padding:13px 10px;
  border-bottom:1px solid #dfe0e4;
  font-weight:normal;
  font-size:14px;
  letter-spacing:-0.04em;
  text-decoration:none;
  color:#373737
}
@media(min-width: 1024px){
.footer__item[data-v-3e2f15be]{
    border-bottom:none
}
}
@media(min-width: 1280px){
.footer__item[data-v-3e2f15be]{
    padding:0 0 20px
}
}
.footer__item-section[data-v-3e2f15be]{
  font-size:14px;
  font-weight:bold;
  line-height:1;
  text-transform:uppercase;
  color:#373737
}
@media(min-width: 1280px){
.footer__item-section[data-v-3e2f15be]{
    padding:0 0 25px
}
}
.footer__item-section--mb-20[data-v-3e2f15be]{
  margin:0 0 20px 0
}
@media(min-width: 1280px){
.footer__item-section--mb-20[data-v-3e2f15be]{
    margin:0
}
}
.footer__item-external-resources[data-v-3e2f15be]{
  padding:13px 8px 50px 8px
}
@media(min-width: 1280px){
.footer__item-external-resources[data-v-3e2f15be]{
    padding:0 0 40px;
    display:flex;
    flex-direction:column
}
}
.footer__item h4[data-v-3e2f15be]{
  margin:0 0 20px 0
}
@media(min-width: 1280px){
.footer__item h4[data-v-3e2f15be]{
    margin:0
}
}
@media(min-width: 1280px){
.footer__item[data-v-3e2f15be]{
    flex-direction:column
}
}
.footer__mobile[data-v-3e2f15be]{
  display:inline-block;
  margin-bottom:10px;
  margin-right:20px
}
@media(min-width: 1024px){
.footer__mobile[data-v-3e2f15be]{
    margin-right:0
}
}
.footer__mobile[data-v-3e2f15be]:last-child{
  margin:0
}
@media(max-width: 1280px){
.footer__mobile[data-v-3e2f15be]:last-child{
    margin-bottom:10px
}
}
.footer__mobile__img[data-v-3e2f15be]{
  width:100%;
  max-width:215px
}
.footer__network[data-v-3e2f15be]{
  display:inline-block;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  font-size:12px;
  line-height:1;
  text-decoration:none;
  margin-right:20px;
  text-transform:uppercase;
  color:#373737
}
.footer__network[data-v-3e2f15be]:last-child{
  margin:0
}
@media(min-width: 1024px){
.footer__network[data-v-3e2f15be]{
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0 0 15px 0
}
.footer__network[data-v-3e2f15be]:last-child{
    margin-bottom:0
}
}
.footer__network__img[data-v-3e2f15be]{
  width:53px;
  box-sizing:content-box
}
@media(min-width: 1024px){
.footer__network__img[data-v-3e2f15be]{
    padding-right:16px
}
}
.footer__network__link[data-v-3e2f15be]{
  display:none
}
@media(min-width: 1024px){
.footer__network__link[data-v-3e2f15be]{
    display:inline-block
}
}
.footer a[data-v-3e2f15be]{
  border:none
}
.footer a[data-v-3e2f15be]:visited{
  color:#373737
}
.footer a[data-v-3e2f15be]:hover{
  color:#00aafa
}